<template>
  <div>
    <cards-tickets />
  </div>
</template>

<script>
import CardsTickets from '@/components/Tickets/tecnico/CardsTickets.vue'

export default {
  components: {
    CardsTickets,
  },
}
</script>
